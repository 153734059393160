<template>

  <div class="layout-footer py-2">

    <div class="grid">

      <div :class="(displayFormasPagamento)?'col-12 md:col-3 py-3 text-center md:text-left':'col-12 md:col-8 py-3 text-center md:text-left'">

        <span style="--aspect-ratio:1/1; width: 40px; vertical-align: middle" class="inline-block border-circle overflow-hidden">
          <img :src="empresa.foto" alt="">
        </span>

        <span class="inline-block font-light ml-2" style="vertical-align: middle; font-size: 1.1em;">
          {{ empresa.nomeEmpresa }}<br>

          <span v-if="$utils.validString(empresa.configuracoes.ECOMMERCE_IDENTIFICACAO_CNPJ) == ''">{{$utils.formatCnpjCpf(empresa.cnpj)}}</span>
          <span v-else-if="$utils.isCNPJ($utils.validString(empresa.configuracoes.ECOMMERCE_IDENTIFICACAO_CNPJ))">{{$utils.formatCnpjCpf(empresa.configuracoes.ECOMMERCE_IDENTIFICACAO_CNPJ)}}</span>
          <span v-else-if="empresa.id != 'uyVLnGnmo7X8JB20DBZWo1Xa85m2'">{{$utils.formatCnpjCpf(empresa.cnpj)}}</span>
        </span>

      </div>

      <div class="col-12 md:col-5 text-center" v-if="displayFormasPagamento">
        
        <div class="mb-1">Formas de pagamentos</div>

        <div id="logos-formas-pagamento">
          <Image class="mb-2 md:mb-0 inline-block"
          :src="require('../assets/selos/visa.png')" 
            imageStyle="width:60px" />
          <Image class="mb-2 ml-3 md:ml-2 md:mb-0 inline-block"
            :src="require('../assets/selos/mastercard.png')" 
            imageStyle="width:60px" />
          <Image class="mb-2 ml-3 md:ml-2 md:mb-0 inline-block" 
            :src="require('../assets/selos/hipercard.png')" 
            imageStyle="width:60px" />
          <Image class="mb-2 ml-3 md:ml-2 md:mb-0 inline-block"
            :src="require('../assets/selos/americanexpress.jpeg')" 
            imageStyle="width:60px" />
          <Image class="mb-2 ml-3 md:ml-2 md:mb-0 inline-block" 
          :src="require('../assets/selos/elo.png')" 
            imageStyle="width:60px" />
          <Image class="mb-2 ml-3 md:ml-2 md:mb-0 inline-block" 
            :src="require('../assets/selos/pix.png')" 
            imageStyle="width:60px" />
        </div>

      </div>

      <div class="col-5 md:col-2 text-center">

        <div class="mb-1">Envios</div>

        <img
          src="@/assets/selos/melhorenvio.png"
          style="width:65px;" class="inline-block" />

      </div>

      <div class="col-7 md:col-2 text-center">

        <div class="mb-1">Selos de seguraça</div>

        <a href="https://www.google.com/transparencyreport/safebrowsing/diagnostic/?hl=pt-BR#url=loja.npc.software" target="_blank" class="inline-block" style="vertical-align: middle">
          <img src="@/assets/selos/Google_Loja_Segura.svg?cccfc=1"
            style="height: 35px; background: white;" alt="Seu pedido com Segurança" title="Site Seguro" />
        </a>

        <img src="@/assets/selos/100_https.svg?cccfc=1"
          style="height: 35px; background: white; vertical-align: middle" class="ml-2 inline-block" alt="Seu pedido com Segurança" title="Site Seguro">

      </div>

      <div class="col-12 text-center">
        <span style="color: rgb(123 189 255);">&copy; NPC Store - E-commerce - {{ $version }}</span>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      displayFormasPagamento: true,
      empresa: { foto: "", nomeEmpresa: "", cnpj: "", config: {} },
      mostrarCartoesRodape: true,
    };
  },
  mounted() {
    document.body.classList.add("with-footer");
  },
  watch: {
    "$root.empresa": {
      handler: function (empresa) {
        const self = this;

        if (empresa != null) {
          self.empresa = empresa;
          this.displayFormasPagamento = empresa.configuracoes.ECOMMERCE_LAYOUT_BANDEIRAS_CARTOES;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .layout-footer {
    background-color: var(--primaryBackgroundColor, $primaryBackgroundColor);
    color: white;
  }

  .layout-footer > .grid {
    max-width: 1000px;
    margin: auto;
  }

  @media screen and (max-width: 500px) {

    #logos-formas-pagamento {
      width: 80%;
      margin: 0 auto;
    }

  }

</style>