<template>
  <div class="layout-topbar fixed top-0 right-0 left-0 px-1 md:px-5 pt-1">
    <div class="grid">
      
      <div class="col relative">
        <span class="absolute pi pi-search text-xl z-1"></span>
        <AutoComplete
          ref="autoBusca"
          placeholder="O que deseja?"
          v-model="buscaValor"
          :suggestions="buscaFiltrada"
          @complete="buscar($event)"
          field="nomeApresentacao"
          inputClass="w-full pl-5"
          class="w-full flex"
          @item-select="click">
          <template #option="slotProps">
        <div class="flex align-options-center">
            <img :alt="slotProps.option.name" :src="slotProps.option.imagen1" :class="`mr-2`" style="max-width: 50px; max-height: 60px;" />
            <div>{{ slotProps.option.nomeApresentacao }}</div>
        </div>
    </template>
      </AutoComplete>
      </div>

      <div
        class="layout-profile-buttons col-fixed text-right"
        style="width: 195px"
      >
        <Button
        icon="pi pi-heart"
          class="
            layout-like-button layout-top-button
            border-circle border-none
            w-2rem
            h-2rem
            mr-3
            overflow-visible
          "
          @click="$router.push({name: 'busca',params: {text: 'Favoritos',},})"
        >
          <span class="pi pi-heart text-xl"></span>
        </Button>
        <Button
          icon="pi pi-shopping-cart text-xl"
          class="
            layout-cart-button layout-top-button
            border-circle border-none
            w-2rem
            h-2rem
            mr-3
            overflow-visible
          "
          @click="$router.push('/carrinho')"
          v-badge.danger="carrinhoQtd"
        >
        </Button>
        <Button
          icon="pi pi-book text-xl"
          class="
            layout-book-button layout-top-button
            border-circle border-none
            w-2rem
            h-2rem
            mr-3
            overflow-visible
          "
          @click="$router.push('/pedidos')"
          v-badge.danger="pedidosQtd"
        >
        </Button>
        <Button
          icon="pi pi-user text-xl"
          class="
            layout-profile-button layout-top-button
            border-circle border-none
            w-2rem
            h-2rem
          "
          @click="displayCadastro = true"
        >
        </Button>
      </div>
    </div>
  </div>

  <Dialog
    :position="$utils.isMobileCheck() ? 'bottom' : 'center'"
    v-model:visible="displayCadastro"
    style="width: 530px; max-width: 98%"
    :modal="true"
    :closable="true"
  >
    <template #header>
      <h5 class="m-0 text-xl font-medium">
        <i class="pi pi-user" style="font-size: 1.3rem"></i> Autenticação
      </h5>
    </template>
    <CadastroCliente
      @deslogado="displayCadastro = false"
      @logado="displayCadastro = false"
    />
  </Dialog>
</template>

<script>
import CadastroCliente from "./CadastroCliente.vue";

export default {
  data() {
    return {
      displayCadastro: false,

      buscaValor: null,
      buscaFiltrada: null,
      empresa: {foto:""},
      carrinhoQtd: 0,
      pedidosQtd:0
    };
  },
  mounted() {
    document.body.classList.add("with-topbar");
  },
  watch: {
    "$root.carrinhoQtd": {
      handler: function (qtd) {
        this.carrinhoQtd = qtd;
      },
      deep: true,
      immediate: true,
    },
    "$root.empresa": {
      handler: function (empresa) {
        const self = this;

        if (empresa != null) {
          self.empresa = empresa;
        }
      },
      deep: true,
      immediate: true,
    },
    "$root.pedidosQtd": {
      handler: function (qtd) {
        this.pedidosQtd = qtd;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    buscaInterna: async function(q) {

      if(this.$root.conteudo.length == 0)
        return false;

      let resultados = [];

      q = q.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

      for(const c of this.$root.conteudo) {

        if(typeof c.nome != "undefined") {

          const categoria = c.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();

          if(categoria.indexOf(q) != -1) {
            resultados.push({
              id: c.id,
              tipo: "categoria",
              nomeApresentacao: c.nome,
              nomeLimpo: categoria
            });
          }
          
        }

        for(const i of c.itens) {
          //console.log(i.imagens[0])
          if(typeof i.nome == "undefined")
            i.nome = "";
          const nome = i.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();

          if(typeof i.descricao == "undefined")
            i.descricao = "";
          const descricao = i.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();

          let referencia;
          if(typeof i.referencia != "undefined")
            referencia = i.referencia.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
          else
            referencia = "";

          if(nome.indexOf(q) != -1 || descricao.indexOf(q) != -1 || referencia.indexOf(q) != -1) {
            resultados.push({
              id: i.id,
              tipo: "produto",
              nomeApresentacao: i.nome,
              nomeLimpo: nome,
              imagen1: i.imagens[0]
            });
          }

        }

      }

      this.buscaFiltrada = [];

      for(const r of resultados) {
        if(this.buscaFiltrada.findIndex((obj) => obj.nomeLimpo == r.nomeLimpo) == -1)
          this.buscaFiltrada.push(r);
      }
      //console.log(this.buscaFiltrada)
      return this.buscaFiltrada.length > 0;

    },
    buscar: async function (event) {

      if(await this.buscaInterna(event.query))
        return;

      // TODO ATUALIZAR PARA A NOVA API
      this.buscaFiltrada = [];
      const res = await this.$apiv3.get("/v2/produtos?fields=nome,id,imagen1&tirarWeb=\\false&perPage=20&str="+event.query);
      //console.log(res.data)
      if (res.data.success) {
        for(let p of res.data.data){
          let ob = {
              id: p.id,
              tipo: "produto",
              nomeApresentacao: p.nome,
              nomeLimpo: p.nome,
              imagen1:p.imagen1
            };
            this.buscaFiltrada.push(ob);
        }
        
        // console.log( this.buscaFiltrada)
        if(this.buscaFiltrada.length > 0)
          this.$refs.autoBusca.show();

      }
    },
    click(t) {
      this.$router.push({
        name: "busca",
        params: {
          text: t.value.nomeApresentacao,
        },
      });
    },
  },
  components: {
    CadastroCliente,
  },
};
</script>

<style lang="scss" scoped>
.layout-topbar {
  background-color: var(--primaryBackgroundColor, $primaryBackgroundColor);
  z-index: 999;
}

.layout-top-button {
  background-color: $alternativeBackgroundColor;
  color: var(--secondaryColor, $secondaryColor);
  line-height: 0.5;
}

.pi-search {
  top: 0.95rem;
  left: 0.9rem;
  color: var(--secondaryColor, $secondaryColor);
}

.layout-topbar > .grid {
  max-width: 1000px;
  margin: auto;
}

.p-button:enabled:active, .p-button:enabled:hover {
  background: var(--primaryBackgroundColor, $primaryBackgroundColor);
  color: #ffffff;
  border-color: #ffffff;
}

</style>