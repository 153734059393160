import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/erro',
        name: 'erro',
        component: () => import('./telas/Erro.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: true
        }
    },
    {
        path: '/',
        name: 'home',
        component: () => import('./telas/Home.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: false
        }
    },
    {
        path: '/carrinho',
        name: 'carrinho',
        component: () => import('./telas/Carrinho.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: false
        }
        
    },
    {
        path: '/pedidos',
        name: 'pedidos',
        component: () => import('./telas/Pedidos.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: false
        }
        
    },
    {
        path: '/busca/:text',
        name: 'busca',
        component: () => import('./telas/Busca.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: false
        }
        
    },
    {
        path: '/pagamento/:id',
        name: 'pagamento',
        component: () => import('./telas/Pagamento.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: false
        }
    },
    {
        path: '/mostrastorys/:id',
        name: 'mostrastorys',
        component: () => import('./telas/MostraStorys.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: true
        }
    },
    {
        path: '/tabela-campanha/:idE/:idC',
        name: 'tabela-campanha',
        component: () => import('./telas/TabelaCampanha.vue'),
        meta: {
          requiresAuth: false,
          freeLayout: true
        }
      },
      {
        path: '/home-site/:idE/:idV',
        name: 'home-site',
        component: () => import('./telas/HomeSite.vue'),
        meta: {
          requiresAuth: false,
          freeLayout: true
        }
      }, 
      {
        path: '/produto-detalhes/:idE/:idP',
        name: 'produto-detalhes',
        component: () => import('./telas/ProdutoDetalhes.vue'),
        meta: {
          requiresAuth: false,
          freeLayout: false
        }
      },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
