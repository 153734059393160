<template>

<div class="carrocel-component relative px-2 md:px-0">
    <div class="carrocel-overflow w-full">
        <div class="flex carrocel-list" :style="'width: ' + containerW + 'px; margin-left: ' + margin + 'px; --h16x9: ' + itens16x9H + 'px'">
            <div class="carrocel-item" v-for="item of itens" :key="item.id" :style="'width: ' + itemW + 'px'">
                <component :is="componente" :value="item"></component>
            </div>
        </div>
    </div>
    <div class="carrocel-prev"><Button icon="pi pi-angle-left" @click="prev" /></div>
    <div class="carrocel-next"><Button icon="pi pi-angle-right" @click="next" /></div>
</div>

</template>

<script>

export default {
    name: "Carrocel",
    props: {
        componente: String,
        itens: {
            type: Array,
            default: () => []
        },
        breakpoints: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            margin: 0,
            containerW: 0,
            itens16x9H: 0,
            itemW: 0,

            qtd: this.itens.length,
            qtdVisivel: 0,
            index: 0,
        }
    },
    mounted() {

        const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        this.qtdVisivel = 1;

        let bp = this.$utils.getStdObject(this.breakpoints);

        bp = bp.sort((a, b) => a.largura - b.largura);

        for(const b of bp) {
            if(b.largura > width) {
                this.qtdVisivel = b.visivel;
                break;
            }
        }

        const tamanhoBase = this.$el.offsetWidth / (this.qtdVisivel+1);
        const tamanhoInvisivel = tamanhoBase/2;
        const tamanhoVisivel = this.$el.offsetWidth - tamanhoInvisivel;
        const tamanhoIndividual = parseInt(tamanhoVisivel/this.qtdVisivel);

        this.itemW = tamanhoIndividual;

        this.containerW = this.qtd*this.itemW;

        const padding = 16; //configurado no css

        this.itens16x9H = ((16*(this.itemW-padding))/9);

    },
    methods: {
        next: function() {

            if(this.index >= this.qtd-this.qtdVisivel) {
                this.margin = 0;
                this.index = 0;
            } else {

                if(this.index == this.qtd-this.qtdVisivel-1) {
                    this.margin = ((this.itemW*this.qtd - this.$el.offsetWidth) * -1);
                } else {
                    this.margin = (this.itemW*(this.index+1) * -1); 
                }
                this.index += 1;

            }

        },
        prev: function() {
            
            if(this.index <= 0) {
                this.margin = ((this.itemW*this.qtd - this.$el.offsetWidth) * -1);
                this.index = this.qtd-this.qtdVisivel;
            } else {
                this.margin = (this.itemW*(this.index-1) * -1); 
                this.index -= 1;
            }

        },
    },
}
</script>

<style lang="scss">
    .is-touch .carrocel-prev, .is-touch .carrocel-next {
        display: none !important;
    }

    .is-touch .carrocel-overflow {
        overflow: scroll !important;
    }

</style>

<style lang="scss" scoped>

    .carrocel-list {
        transition: margin-left 500ms;
    }

    .carrocel-item {
        padding-right: 16px;
    }

    .carrocel-overflow {
        overflow: hidden;
    }

    .carrocel-prev .p-button:focus, .carrocel-next .p-button:focus {
        box-shadow: none;
    }

    .carrocel-prev, .carrocel-next {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 3;
        display: none;
    }

    .carrocel-component:hover .carrocel-prev, .carrocel-component:hover .carrocel-next {
        display: block;
    }

    .carrocel-prev .p-button, .carrocel-next .p-button {
        background: rgba(0,0,0,0.1);
        border: none;
        width: 2rem;
        height: 100%;
        border-radius: 0.5rem 0 0 0.5rem;
    }

    .carrocel-prev .p-button:hover, .carrocel-next .p-button:hover, .carrocel-prev .p-button:active, .carrocel-next .p-button:active {
        background: rgba(0, 0, 0, 0.6);
    }

    .carrocel-next {
        right: 0;
    }

    .carrocel-next .p-button {
        border-radius: 0;
    }

    :deep(.carrocel-prev) .pi, :deep(.carrocel-next) .pi {
        font-size: 2rem !important;
    }

</style>