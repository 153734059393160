import axios from 'axios';
import { getAuth, getIdToken } from "firebase/auth";
export default class API {

    constructor(api) {
        this.urlApi = api;
        this.fpid = null;
        this.idEmpresa = "";
    }

    setFpid(fpid) {
        if(typeof fpid == "string" && fpid != "")
            this.fpid = fpid;        
    }

    setIdEmpresa(idEmpresa) {
        if(typeof idEmpresa != "string")
            idEmpresa = "";
        this.idEmpresa = idEmpresa;
    }

    async getToken() {

        const currentUser = getAuth().currentUser;

        if(currentUser === null) {

            while(this.fpid == null) {
                await new Promise(resolve => setTimeout(resolve, 500));
            }

            return "Anonymous " + this.fpid;
        } else {
            return "Bearer " + await getIdToken(currentUser, false);
        }

    }

    async headers() {
        return { 
            'Accept': 'application/json',
            'Authorization': await this.getToken(),
            'idEmpresa': this.idEmpresa
        };
    }

    async get(endpoint/*, data = null*/) {

        if(typeof endpoint != "string")
            return null;

        //TRATAR PARAMS typeof data != object || data == null

        return axios({
            method: 'get',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
        });

    }

    async post(endpoint, data = null) {

        if(typeof endpoint != "string")
            return null;

        if(typeof data != "object" || data == null)
            data = {};

        return axios({
            method: 'post',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
            "data" : data
        });

    }

    async put(endpoint, data = null) {

        if(typeof endpoint != "string" || typeof data != "object" || data == null)
            return null;
    
        return axios({
            method: 'put',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
            "data" : data
        });

    }

    async delete(endpoint, data = null) {

        if(typeof endpoint != "string")
            return null;

        if(typeof data != "object" || data == null)
            data = {};

        return axios({
            method: 'delete',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
            "data" : data
        });

    }

    async cache(endpoint, forceUpdate = false, log = false) {

        if(log) console.log("API CACHE: ", endpoint);

        let versionDatabase = "";

        if(!forceUpdate) {

            if(typeof endpoint != "string")
                return null;

            let versionLocal = "";

            const res = await this.get(endpoint + "?onlyVersion=true");
            
            if(typeof res.headers["X-Version"] == "string")
                versionDatabase = res.headers["X-Version"];
            else if(typeof res.headers["x-version"] == "string")
                versionDatabase = res.headers["x-version"];
            else
                console.warn("Api Cache (" + endpoint + "): Controle de Versão Indisponível para o Endpoint! O Download sempre será realizado!");

            if(versionDatabase != "") {

                const version = window.localStorage.getItem("NPC_ECOMMERCE/" + endpoint + "/version");

                if(version)
                    versionLocal = version;

                if(versionDatabase == versionLocal) {
                    if(log) console.log("Api Cache (" + endpoint + "): Carregamento Local.");
                    const data = window.localStorage.getItem("NPC_ECOMMERCE/" + endpoint);
                    if(typeof data == "string" && data != "")
                        return JSON.parse(data);
                    return null;
                }

            }

        }

        if(log) console.log("Api Cache (" + endpoint + "): Carregamento por Download.");

        const res = await this.get(endpoint);

        if(res.data.success) {

            if(typeof res.headers["X-Version"] == "string")
                versionDatabase = res.headers["X-Version"];
            else if(typeof res.headers["x-version"] == "string")
                versionDatabase = res.headers["x-version"];

            if(versionDatabase != "") {
                window.localStorage.setItem("NPC_ECOMMERCE/" + endpoint + "/version", versionDatabase);
                window.localStorage.setItem("NPC_ECOMMERCE/" + endpoint, JSON.stringify(res.data.data));
            }
            return res.data.data;

        }

        if(log) console.log("Api Cache (" + endpoint + "): Falha na execução!");

        return null;

    }

}